export const mobileNavigation = () => {
  // FOR SAME PAGE NAVIGATION -------------------------------------------
  // for (let i = 0; i < links.length; i++)
  //   links[i].addEventListener('click', function() {
  //     document.getElementById('navi-toggle').checked = false;
  //     document.querySelector('.m-navigation__list').classList.toggle('hidden');
  //   });
  // --------------------------------------------------------------------

  document.getElementById('navi-toggle').addEventListener('change', function() {
    document.querySelector('.m-navigation__list').classList.toggle('hidden');
  });
}


